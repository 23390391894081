import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const baseStyle = defineStyle({
  fontWeight: "semibold",
  fontFamily: "Inter",
});

export const headingTheme = defineStyleConfig({
  baseStyle
});
