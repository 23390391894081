import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { useAuth } from "shared/providers/AuthContext";

import PageLoading from "shared/components/PageLoading";
import LazyContent from "shared/components/LazyContent";

import "./App.scss";

const Auth = React.lazy(() => import("pages/Auth"));
const Workspace = React.lazy(() => import("pages/Workspace"));

// Dev modules
const IconsList = React.lazy(() => import("pages/IconsList"));

const App = () => {
  const { loading } = useAuth();

  if (loading) {
    return <PageLoading />;
  }

  return (
    <Routes>
      {!process.env.REACT_APP_IS_PRODUCTION && (
        <>
          <Route
            path="/icons"
            element={<LazyContent element={<IconsList />} />}
          />
        </>
      )}
      <Route path="/auth/*" element={<LazyContent element={<Auth />} />} />
      <Route path="/*" element={<VerifyRoute />} />
    </Routes>
  );
};

const VerifyRoute = () => {
  const { authorized } = useAuth();

  if (!authorized) {
    return <Navigate to="/auth" replace />;
  }

  return <LazyContent element={<Workspace />} />;
};

export default App;
