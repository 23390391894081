import { extendTheme } from "@chakra-ui/react";

import { menuTheme } from "./components/menu";
import { iconTheme } from "./components/icon";
import { buttonTheme } from "./components/button";
import { avatarTheme } from "./components/avatar";
import { headingTheme } from "./components/heading";
import { formLabelTheme, inputTheme } from "./components/input";
import { tableTheme } from "./components/table";
import { modalTheme } from "./components/modal";
import { checkboxTheme } from "./components/checkbox";
import { selectTheme } from "./components/select";
import { textareaTheme } from "./components/textarea";
import { tooltipTheme } from "./components/tooltip";
import { tabsTheme } from "./components/tabs";

export default extendTheme({
  styles: {
    global: {
      body: {
        color: "gray.700",
        fontFamily: '"Inter", sans-serif',
      },
      "svg, img": {
        display: "initial",
      },
    },
  },
  colors: {
    brand: {
      50: "#eee8fe",
      100: "#d1c6fd",
      200: "#b1a0fd",
      300: "#8e79fe",
      400: "#6e5afd",
      500: "#473cfb",
      600: "#3238f4",
      700: "#0030eb",
      800: "#002ae6",
      900: "#1A2B58",
    },
    yellow: {
      50: "#FFF6E5",
      100: "#FFE7B8",
      200: "#FFD78A",
      300: "#FFC75C",
      400: "#FFB72E",
      500: "#FFA800",
      600: "#CC8600",
      700: "#996500",
      800: "#664300",
      900: "#332200",
    },
    m: {
      blue: "#0069FF",
      green: "#18DE97",
      red: "#E74C3C",
    },
  },
  components: {
    Icon: iconTheme,
    Menu: menuTheme,
    Table: tableTheme,
    Modal: modalTheme,
    Input: inputTheme,
    Select: selectTheme,
    Avatar: avatarTheme,
    Button: buttonTheme,
    Heading: headingTheme,
    Checkbox: checkboxTheme,
    FormLabel: formLabelTheme,
    Tooltip: tooltipTheme,
    Textarea: textareaTheme,
    Tabs: tabsTheme,
  },
});
