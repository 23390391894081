import { RandomIdGenerator } from '@opentelemetry/sdk-trace-base'

const SHARED_CHAR_CODES_ARRAY = Array(32)
function getIdGenerator(bytes: number): () => string {
  return function generateId() {
    for (let i = 0; i < bytes * 2; i++) {
      SHARED_CHAR_CODES_ARRAY[i] = Math.floor(Math.random() * 16) + 48
      // valid hex characters in the range 48-57 and 97-102
      if (SHARED_CHAR_CODES_ARRAY[i] >= 58) {
        SHARED_CHAR_CODES_ARRAY[i] += 39
      }
    }
    return String.fromCharCode.apply(
      null,
      SHARED_CHAR_CODES_ARRAY.slice(0, bytes * 2),
    )
  }
}

class DebugSessionIdGenerator extends RandomIdGenerator {
  sessionId: string

  generateTraceId: () => string
  generateShortId: () => string
  generateLongId: () => string

  constructor() {
    super()

    this.generateShortId = getIdGenerator(4)
    this.generateLongId = getIdGenerator(16)
    this.sessionId = ''

    this.generateTraceId = () => {
      if (!this.sessionId?.length) {
        return this.generateLongId()
      }

      const traceId = `d${this.sessionId}${this.generateShortId().substring(0,7)}`

      return traceId
    }
  }

  setSessionId(sessionId: string) {
    this.sessionId = sessionId
  }
}

const idGenerator = new DebugSessionIdGenerator()

export default idGenerator
