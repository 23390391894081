import { createContext, useContext, useState } from "react";
import useEventListener from "shared/hooks/useEventListener";
import OnlineOfflineAlert from "shared/components/OnlineOfflineAlert";

interface IOnlineOfflineContext {
  isOnline: boolean;
}

export const OnlineOfflineContext = createContext<IOnlineOfflineContext>(null);

export const OnlineOfflineProvider = ({ children }) => {
  const [isOnline, setIsOnline] = useState(true);

  const handleOnline = () => {
    setIsOnline(true);
  };

  const handleOffline = () => {
    setIsOnline(false);
  };

  useEventListener("online", handleOnline, window);
  useEventListener("offline", handleOffline, window);

  return (
    <OnlineOfflineContext.Provider
      value={{
        isOnline: isOnline,
      }}
    >
      <OnlineOfflineAlert isOnline={isOnline} />
      {children}
    </OnlineOfflineContext.Provider>
  );
};

export function useOnlineOfflineState() {
  const context = useContext(OnlineOfflineContext);
  if (context === null) {
    throw new Error(
      "useOnlineOfflineState must be used within OnlineOfflineProvider"
    );
  }
  return context;
}
