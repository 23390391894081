import { tabsAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys);

const lineVariant = definePartsStyle(() => ({
  tab: {
    border: "unset",
    bg: "transparent",
    color: "var(--chakra-colors-gray-600)",
    fontSize: "var(--chakra-fontSizes-sm)",
    fontWeight: "medium",
    padding: "0 0 12px",
    margin: "0 16px",
    flex: "0",
    position: "relative",
    _selected: {
      color: "var(--chakra-colors-brand-600)",
      _after: {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "3px",
        bottom: "-1px",
        borderRadius: "3px 3px 0 0",
        backgroundColor: " var(--chakra-colors-brand-500)",
      },
    },
  },
  tablist: {
    borderBottom: "1px solid var(--chakra-colors-gray-100)",
  },
}));

const reverseVariant = definePartsStyle(() => ({
  tab: {
    border: "unset",
    bg: "transparent",
    color: "var(--chakra-colors-gray-500)",
    fontSize: "var(--chakra-fontSizes-sm)",
    fontWeight: "medium",
    padding: "8px 0 0",
    marginRight: 4,
    flex: "0",
    justifyContent: "flex-start",
    position: "relative",
    _after: {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "4px",
      top: "-4px",
      borderRadius: "2px",
      backgroundColor: "var(--chakra-colors-gray-200)",
    },
    _selected: {
      color: "var(--chakra-colors-gray-800)",
      _after: {
        backgroundColor: "var(--chakra-colors-brand-500)",
      },
    },
  },
}));

const variants = {
  line: lineVariant,
  reverse: reverseVariant,
};

export const tabsTheme = defineMultiStyleConfig({ variants });
