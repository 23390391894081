import axios from "axios";
import axiosRetry from "axios-retry";
import { modifyResData, modifyResError } from "./helpers/api.helpers";

const baseURL = process.env.REACT_APP_API_BASE_URL || "";
const apiPrefix = process.env.REACT_APP_API_PREFIX;
const gitPrefix = process.env.REACT_APP_GIT_PREFIX;
const authPrefix = process.env.REACT_APP_AUTH_PREFIX;
const versionPrefix = process.env.REACT_APP_VERSION_PREFIX;
const radarPrefix = process.env.REACT_APP_RADAR_PREFIX;

const apiInstanceBaseURL = baseURL + apiPrefix;
const gitInstanceBaseURL = baseURL + gitPrefix;
const versionInstanceBaseURL = baseURL + versionPrefix;
const authInstanceBaseURL = baseURL + authPrefix;
const radarInstanceBaseURL = baseURL + radarPrefix;

const retryConfigs = {
  retries: 8,
  retryDelay: axiosRetry.exponentialDelay,
};

const apiInstance = axios.create({
  baseURL: apiInstanceBaseURL,
  withCredentials: true,
});

const gitInstance = axios.create({
  baseURL: gitInstanceBaseURL,
  withCredentials: true,
});

const gitPublicInstance = axios.create({
  baseURL: gitInstanceBaseURL,
  withCredentials: true,
});

const versionInstance = axios.create({
  baseURL: versionInstanceBaseURL,
  withCredentials: true,
});

const authInstance = axios.create({
  baseURL: authInstanceBaseURL,
  withCredentials: true,
});

const radarInstance = axios.create({
  baseURL: radarInstanceBaseURL,
  withCredentials: true,
});

axiosRetry(apiInstance, retryConfigs);
axiosRetry(gitInstance, retryConfigs);
axiosRetry(gitPublicInstance, retryConfigs);
axiosRetry(authInstance, retryConfigs);
axiosRetry(radarInstance, retryConfigs);
axiosRetry(versionInstance, retryConfigs);

apiInstance.interceptors.response.use(modifyResData, modifyResError);
gitInstance.interceptors.response.use(modifyResData, modifyResError);
gitPublicInstance.interceptors.response.use(modifyResData, modifyResError);
authInstance.interceptors.response.use(modifyResData, modifyResError);
radarInstance.interceptors.response.use(modifyResData, modifyResError);
versionInstance.interceptors.response.use(modifyResData, modifyResError);

export {
  apiInstance,
  gitInstance,
  gitPublicInstance,
  authInstance,
  radarInstance,
  versionInstance,
  apiInstanceBaseURL,
  gitInstanceBaseURL,
  authInstanceBaseURL,
  versionInstanceBaseURL,
};
