import { useSocket } from "shared/providers/Socket";
import { useAuth } from "shared/providers/AuthContext";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { apiInstance, gitInstance, versionInstance } from "shared/api";

const ErrorHandler = () => {
  const { clearAuthData } = useAuth();
  const navigate = useNavigate();

  const { subscribe } = useSocket();

  useEffect(() => {
    const handleAuthError = (error) => {
      if (error && error.code === 401 && window.location.pathname !== "/auth") {
        localStorage.setItem("redirectTo", window.location.pathname);
        navigate("/auth");
        clearAuthData();
      }
    };

    apiInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        handleAuthError(error);
        return Promise.reject(error);
      }
    );

    versionInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        handleAuthError(error);
        return Promise.reject(error);
      }
    );

    gitInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        handleAuthError(error);
        return Promise.reject(error);
      }
    );
  }, [clearAuthData, subscribe, navigate]);

  return null;
};

export default ErrorHandler;
