import { modalAnatomy as parts } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from "@chakra-ui/styled-system";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  overlay: {
    bg: "blackAlpha.100",
    backdropFilter: "blur(4px)",
  },
  dialog: {},
  footer: {
    pb: 6,
  },
});

const sizes = {
  "4xl": definePartsStyle({
    dialog: defineStyle({ maxW: "800px", borderRadius: "2xl" }),
  }),
};

export const modalTheme = defineMultiStyleConfig({
  sizes,
  baseStyle,
});
