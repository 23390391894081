import { useEffect, useState } from "react";
import { Flex, Icon } from "@chakra-ui/react";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import { CheckCircleIcon } from "shared/icons";

const OnlineOfflineAlert = ({ isOnline }) => {
  const [hasBeenOffline, setHasBeenOffline] = useState(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (!isOnline) {
      setHasBeenOffline(true);
      setVisible(true);
    } else if (hasBeenOffline) {
      setVisible(true);
      setTimeout(() => {
        setVisible(false);
      }, 3000);
    }
  }, [isOnline]);

  if (!visible) {
    return null;
  }

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      bgColor={isOnline ? "green.500" : "red.500"}
      color="white"
      fontWeight="medium"
      width="full"
      height={12}
      transition="all .2s cubic-bezier(.87, 0, .13, 1)"
      top={0}
      left={0}
      zIndex={999999}
    >
      {isOnline ? (
        <>
          <Icon as={CheckCircleIcon} color="white" mr={3} />
          You are back online.
        </>
      ) : (
        <>
          <Icon as={InfoOutlineIcon} color="white" mr={3} />
          You have lost connection to the internet.
        </>
      )}
    </Flex>
  );
};

export default OnlineOfflineAlert;
