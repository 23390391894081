import io from 'socket.io-client'

export class RrwebEventExporter {
  private socket: any
  private queue: any[] = []
  private isConnecting: boolean = false

  constructor(private socketUrl: string, private apiKey: string) {
    this.init()
  }

  private init(): void {
    if (this.isConnecting) return
    this.isConnecting = true

    this.socket = io(this.socketUrl, {
      path: '/v0/radar/ws',
      query: { 'x-api-key': this.apiKey },
      reconnectionAttempts: 2,
      transports: ['websocket'],
    })

    this.socket.on('connect', () => {
      this.isConnecting = false
      this.flushQueue()
    })

    this.socket.on('disconnect', () => {
      this.isConnecting = false
    })

    this.socket.on('connect_error', (error: any) => {
      this.isConnecting = false
    })
  }

  private flushQueue(): void {
    while (this.queue.length > 0 && this.socket && this.socket.connected) {
      const event = this.queue.shift()
      this.socket.emit('debug-session:rrweb:add-event', event)
    }
  }

  public send(event: any): void {
    if (this.socket && this.socket.connected) {
      this.socket.emit('debug-session:rrweb:add-event', event)
    } else {
      this.queue.push(event)
      this.init()
    }
  }

  public close(): void {
    if (this.socket) {
      this.socket.close()
    }
  }
}
