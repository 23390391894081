import { record } from 'rrweb'
import { pack, unpack } from '@rrweb/packer'
import RRWebPlayer from 'rrweb-player'
import { getRecordConsolePlugin } from '@rrweb/rrweb-plugin-console-record'

import { RrwebEventExporter } from './exporter'
import { SessionDebuggerConfigs } from '../types'
import { IndexedDBService } from './indexedDbService'
import { CanvasReplayerPlugin } from './canvas-plugin'

export class RecorderBrowserSDK {
  private stopFn?: () => void
  private config?: SessionDebuggerConfigs
  private exporter: RrwebEventExporter | undefined
  private indexedDBService: IndexedDBService

  constructor() {
    this.indexedDBService = new IndexedDBService()
  }

  /**
   * Initializes the recorder SDK with configuration settings.
   * @param config - Configuration settings for the session debugger.
   */
  init(config: SessionDebuggerConfigs): void {
    this.config = config
    this.exporter = new RrwebEventExporter(
      config.exporterApiBaseUrl || '',
      config.apiKey,
    )
  }

  /**
   * Starts recording events for a given session ID.
   * @param sessionId - The ID of the session to record events for.
   */
  start(sessionId: string | null): void {
    if (!this.config) {
      throw new Error(
        'Configuration not initialized. Call init() before start().',
      )
    }
    this.stopFn = record({
      packFn: pack,
      recordCanvas: this.config.canvasEnabled,
      sampling: { canvas: 5 },
      maskAllInputs: true,
      dataURLOptions: { type: 'image/webp', quality: 0.1 },
      plugins: [
        getRecordConsolePlugin({ level: ['info', 'log', 'warn', 'error'] }),
      ],
      emit: async (event) => {
        if (this.exporter) {
          const timestamp = Date.now()
          this.exporter.send({ event, debugSessionId: sessionId, timestamp })
          await this.indexedDBService.saveEvent(event)
        }
      },
    })
  }

  /**
   * Stops the recording of events.
   */
  stop(): void {
    if (this.stopFn) {
      this.stopFn()
    }
  }

  /**
   * Prepares a preview of the recorded events using the specified canvas plugin.
   * @param canvasEnabled - Optional parameter to replay canvas.
   */
  async preview(canvasEnabled?: boolean): Promise<void> {
    const storedEvents = await this.indexedDBService.getAllEvents()
    if (storedEvents.length > 1) {
      const replayerFrame = document.getElementById(
        'mpPlayerFrame',
      ) as HTMLElement
      new RRWebPlayer({
        target: replayerFrame!,
        props: {
          events: storedEvents.map(unpack),
          autoPlay: false,
          skipInactive: true,
          showController: true,
          width: replayerFrame?.offsetWidth,
          height: replayerFrame?.offsetHeight,
          plugins: canvasEnabled
            ? [CanvasReplayerPlugin(storedEvents.map(unpack))]
            : [],
        },
      }).getReplayer()

      setTimeout(() => {
        const playButton = document.querySelector(
          '.rr-controller__btns button',
        ) as HTMLElement
        if (playButton) {
          playButton.focus()
        }
      }, 0)
    }
  }

  /**
   * Clears stored events from IndexedDB and resets the replay container.
   */
  async clearStoredEvents(): Promise<void> {
    await this.indexedDBService.clearEvents()
    const replayContainer = document.getElementById('mpPlayerFrame')
    if (replayContainer) {
      replayContainer.innerHTML = ''
    }
  }
}
