import { MultiplayerSessionDebugger } from './session-debugger'
import { CanvasReplayerPlugin } from './rrweb/canvas-plugin'

const MultiplayerSessionDebuggerInstance = new MultiplayerSessionDebugger()

// Attach the instance to the global object (window in browser)
if (typeof window !== 'undefined') {
  window['MultiplayerSessionDebugger'] = MultiplayerSessionDebuggerInstance
}

export default MultiplayerSessionDebuggerInstance

export { CanvasReplayerPlugin }
