import { menuAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(menuAnatomy.keys)


const baseStyle = definePartsStyle({
  button: {},
  list: { p: '2', borderColor: 'gray.200' },
  item: { borderRadius: 'md', px: '2' },
  groupTitle: {},
  command: {},
  divider: {},
})


export const menuTheme = defineMultiStyleConfig({ baseStyle })