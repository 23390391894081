import { CapturingIcon, CheckmarkIcon, CloseIcon, RecordIcon } from './icons'

/**
 * BUTTON_STATE_ENUM defines the possible states of the recorder button.
 * It includes READY, RECORDING, CANCEL, and SENT states.
 */
export enum BUTTON_STATE_ENUM {
  READY = 'READY',
  RECORDING = 'RECORDING',
  CANCEL = 'CANCEL',
  SENT = 'SENT',
}

/**
 * ButtonState type defines the allowed string values for button states.
 */
export type ButtonState = 'READY' | 'RECORDING' | 'CANCEL' | 'SENT';

/**
 * buttonStates object provides properties for each button state:
 * READY, RECORDING, CANCEL, and SENT.
 */
export const buttonStates = {
  READY: {
    icon: RecordIcon,
    tooltip: 'Click to record a bug',
    classes: [],
    excludeClasses: ['animate-rotate', 'animate-bounce', 'mp-button-blue'],
  },
  RECORDING: {
    icon: CapturingIcon,
    tooltip: 'Click to stop your recording',
    classes: ['animate-bounce'],
    excludeClasses: ['mp-button-blue', 'animate-rotate'],
  },
  CANCEL: {
    icon: CloseIcon,
    tooltip: 'Click to cancel',
    classes: [],
    excludeClasses: ['animate-rotate', 'animate-bounce', 'mp-button-blue'],
  },
  SENT: {
    icon: CheckmarkIcon,
    tooltip: 'We\'ve sent it over! Thanks!',
    classes: ['mp-button-blue'],
    excludeClasses: ['animate-rotate', 'animate-bounce'],
  },
}